import { CommonModule, registerLocaleData } from '@angular/common';
/* ------ LANGUAGE ---------------------*/
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';

/* ------ CORE IMPORTS -----------  */
import { CoreFrontendSharedModule, FeatureRouterLinkResolverService } from '@core/frontend-shared';
import { CoreRouterModule, provideRootRoutes, routerProviderFactory } from '@core/frontend-shared/router';

/* ------ COMMON IMPORTS -----------  */
import { CmsFrontendSharedModule } from '@common/cms/frontend-shared';
import { ConsoleFrontendSharedModule } from '@common/console/frontend-shared';
import { FeedbackFrontendModule } from '@common/feedback/frontend';
import { LayoutFrontendSharedModule } from '@common/layout/frontend-shared';
import { LayoutContentbarsModule } from '@common/layout/frontend-shared/contentbars';
import { LayoutRouterOutletUiFullscreenModule } from '@common/layout/frontend-shared/router-outlet-fullscreen';
import { LayoutSidebarModule } from '@common/layout/frontend-shared/sidebar';
import { LayoutToolbarComponent } from '@common/layout/frontend-shared/toolbar';
import { LocalstateFrontendSharedModule } from '@common/localstate/frontend-shared';
import { MaintenanceFrontendModule } from '@common/maintenance/frontend';
import { MessagesFrontendModule } from '@common/messages/frontend';
import { MinimalUiModule } from '@common/ui/frontend-shared';
import { UiScreenServiceModule } from '@common/ui/frontend-shared/screen-service';
import { UiTabMenuModule } from '@common/ui/frontend-shared/tabmenu';
import { VersioncheckFrontendModule } from '@common/versioncheck/frontend';
import { UtilsFrontendSharedModule } from '@core/frontend-shared/utils';

/* ------ FEATURE IMPORTS -----------  */
import { AccessMembershipFrontendPlugin } from '@common/membership/frontend';
import { UiRequiredModule } from '@pflegetheke/ui/frontend-shared';
import { LayoutFrontpageModule } from '@pflegetheke/ui/frontend-shared/layout-frontpage';

/* ------ LOCAL IMPORTS -----------  */
import { UserForceNewPasswordComponent } from '@common/users/frontend-shared';
import { AppComponent } from './components/app.component';
import { AppImprintComponent } from './components/imprint/imprint.component';
import { LandingpageComponent, LandingpageModule } from './components/landingpage/landingpage.component';
import { AppLoginComponent } from './components/login/login.component';
import { AppManagementDynamicRedirectComponent } from './components/management/dynamic-redirect.component';
import { AppManagementComponent } from './components/management/management.component';
import { AppPrivacyComponent } from './components/privacy/privacy.component';
import { ScopeMetadataService } from './scope-metadata.service';

import features from './features';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';

registerLocaleData(localeDe);








const preloadCmsModulesForRoutes = [
	'/','/privacy','/imprint'
]

const states: any[] = [];

const persist: string[] = ['local','language'];

@NgModule({
	declarations: [
		AppComponent,
		AppImprintComponent,
		AppPrivacyComponent,
		AppManagementComponent,
		AppManagementDynamicRedirectComponent,
	],
	imports: [
		BrowserModule,
		ServiceWorkerModule.register('ngsw-worker.js', { 
			enabled: IS_PRODUCTION, 
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000' 
		}),
		ReactiveFormsModule,

		// must be imported BEFORE Core library!
		NgxsModule.forRoot(states, { 
			developmentMode: !IS_PRODUCTION,
			selectorOptions: { suppressErrors: false }
		}),
		NgxsStoragePluginModule.forRoot({ key: persist }),
		(!IS_PRODUCTION) ? NgxsReduxDevtoolsPluginModule.forRoot({}) : [],

		CoreFrontendSharedModule.forRoot({}),
		UserForceNewPasswordComponent,
		
		RouterModule.forRoot([/* routes provided using provideRootRoutes()! */], { 
			initialNavigation: 'enabledBlocking',
			paramsInheritanceStrategy:'always',
			scrollPositionRestoration:'top',
			// anchorScrolling: 'enabled',
		}),
		CoreRouterModule,
		BrowserAnimationsModule,
		ConsoleFrontendSharedModule,
		// MessagesFrontendModule,
		
		UtilsFrontendSharedModule,
		CmsFrontendSharedModule.forRoot({preloadCmsModulesForRoutes}),
		MessagesFrontendModule,
		LayoutFrontpageModule,
		LocalstateFrontendSharedModule,
		UiScreenServiceModule.forRoot(),
		MinimalUiModule.forRoot(),
		UiRequiredModule,
		UiTabMenuModule,

		LayoutFrontendSharedModule.forRoot(),
		LayoutContentbarsModule,
		LayoutToolbarComponent,
		LayoutSidebarModule,
		LayoutRouterOutletUiFullscreenModule,

		VersioncheckFrontendModule,
		MaintenanceFrontendModule,
		FeedbackFrontendModule,

		LandingpageModule,
		AppLoginComponent,

		...features,
		
	],
	providers: [
		{ provide: APP_ID, useValue: 'pflegetheke-frontend-ssr' },
		{provide: LOCALE_ID, useValue: "de-DE"},
		provideHttpClient(
			withFetch(),
			withInterceptorsFromDi(),
		),
		provideClientHydration(),
		provideRootRoutes([
			{ path: 'login', data:{ pageTitle:'Login/Anmeldung', layout:'fullscreen,showBottomBar' }, component: AppLoginComponent },
			{ path: 'imprint', data:{ pageTitle:'Impressum' }, component: AppImprintComponent },
			{ path: 'privacy', data:{ pageTitle:'Datenschutz' }, component: AppPrivacyComponent },
			{ path: 'management', data:{ pageTitle:'Verwaltung' }, component: AppManagementComponent, children:[
				{path:'', pathMatch:'full', component:AppManagementDynamicRedirectComponent}
			] },
			{ path: 'manager', redirectTo:'management/manager' },
			{ path: '', pathMatch: 'full', component: LandingpageComponent, data: {layout:'fullscreen,showBottomBar'}	},
				]),
		...routerProviderFactory(),
	],
	bootstrap: [AppComponent],
	exports: [
		CommonModule,
		UiRequiredModule,
	]
})
export class AppModule {
	constructor(
		test:FeatureRouterLinkResolverService,
		scopeMeta:ScopeMetadataService,
	) {}
}
